import React, { useEffect } from 'react';
import './Slider.css';
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

const Slider = ({ slides }) => {
  shuffle(slides);
  useEffect(() => {
    new Swiper('.slider', {
      effect: 'cube',
      autoplay:{
          delay: 7500,
          pauseOnMouseEnter: true,
          disableOnInteraction: true,
      },
      modules:[Autoplay, Pagination, Navigation, Scrollbar],
      direction: 'horizontal',
      loop: true,
      spaceBetween: 10,
      slidesPerView: 1,
      breakpoints: {
          // when window width is >= 768px
          768: {
            slidesPerView: 1,
          }
      },
      scrollbar: {
          dragClass: 'swiper-scrollbar-drag',
          el: '.swiper-scrollbar',
          draggable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }, []);
  
  return (
    <div className="slider swiper">
      <div className="swiper-wrapper">
        {slides.map((sld, indexq) => (
          <div className="swiper-slide" key={indexq}>
            <div className='content-slider'>
              <img alt={sld.alt} src={sld.image}  />
            </div>
          </div>
        ))}
    </div>
    <div className="swiper-button-prev"></div>
    <div className="swiper-button-next"></div>
    <div className="swiper-scrollbar"></div>
    </div>
  );
};

export default Slider;
