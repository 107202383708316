import React, { useState, useRef } from 'react';
import './SliderShow.css';
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { FiChevronsDown } from "react-icons/fi";

const SliderShow = ({ shows, autoShowDelay = 3000 }) => {
    new Swiper('.shows', {
        autoplay:{
            delay: 7500,
            pauseOnMouseEnter: true,
            disableOnInteraction: true,
        },
        modules:[Autoplay, Pagination, Navigation, Scrollbar],
        direction: 'horizontal',
        loop: false,
        spaceBetween: 10,
        slidesPerView: window.innerWidth > 768 ? 3 : 1,
        breakpoints: {
            // when window width is >= 768px
            768: {
              slidesPerView: 3,
            }
        },
        scrollbar: {
            dragClass: 'swiper-scrollbar-drag',
            el: '.swiper-scrollbar',
            draggable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
    });

    const [Height, setHeight] = useState('170px');
    const ref = useRef(null);
    const handleInfoToggle = () => {
        ref.current.setAttribute("height", '320px');
        (Height === '170px') ? setHeight('320px') : setHeight('170px');
    };

    return (
        <div className="shows swiper">
            <div className="swiper-wrapper">
                {shows.map((show, index) => (
                    <div className="swiper-slide" key={index}>
                        <div className='content-slide'>
                            <div className='head-slide'>
                                <img src={show.image} alt={show.alt} className="show-image" />
                            </div>
                            <div className='body-slide'>
                                <h2>{show.title}</h2>
                                <p>{show.alt}</p>
                                <hr></hr>
                                <div className='schedule' ref={ref} style={{ height: Height}}>
                                    <div className='schedule-item'>
                                        <div className='header'>
                                            <p>EN VIVO</p>
                                        </div>
                                        {show.schedule?.map((item, indexl) => (
                                            item.tipo === "live" &&
                                            <div key={indexl} className='country-item'>
                                                <h3>{item.dia}</h3>
                                                <div className='flags'>
                                                    {item.time?.map((tm, indel) => (
                                                        <div key={indel} className='flag-item'>
                                                            <img alt='img' src={tm.flag}  />
                                                            <div className='time'>
                                                                <p>{tm.ini} - {tm.fin}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='schedule-item'>
                                        <div className='header'>
                                            <p>REPETICIÓN</p>
                                        </div>
                                        {show.schedule?.map((item, indexr) => (
                                            item.tipo === "repeat" &&
                                            <div key={indexr} className='country-item'>
                                                <h3>{item.dia}</h3>
                                                <div className='flags'>
                                                    {item.time?.map((tm, ind) => (
                                                        <div key={ind} className='flag-item'>
                                                            <img alt='img' src={tm.flag}  />
                                                            <div className='time'>
                                                                <p>{tm.ini} - {tm.fin}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div> 
                                        ))}
                                    </div>
                                </div>
                                {show.schedule.length > 2 &&
                                <div className='button-more'>
                                    <button className='btns' onClick={handleInfoToggle}><FiChevronsDown /></button>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
            <div className="swiper-scrollbar"></div>
        </div>
    );
};

export default SliderShow;
