import React from 'react';
import SliderShow from './SliderShow';
import './Show.css';
import col from '../../assets/images/flag_col.png';
import mex from '../../assets/images/flag_mex.png';



const Show = () => {
  
  const slides = [
    { image: './shows/cmd_soundsystem.jpg', title:'Comunidad Mexicana Del Sound System', alt: "La Comunidad Mexicana Del Sound System es una plataforma dedicada a la difusión de eventos del soundsystem reggae Mexicano y el mundo eventos, flyers, sesiones y mucho dub.", schedule:[{"tipo":"live","dia":"Martes y Sábado", "time":[{"flag":"./flag_col.png","ini":"10:00pm","fin":"12:00am"},{"flag":"./flag_mex.png","ini":"9:00pm","fin":"11:00pm"}]}, {"tipo":"live","dia":"Jueves", "time":[{"flag":"./flag_col.png","ini":"11:00pm","fin":"1:00am"},{"flag":"./flag_mex.png","ini":"10:00pm","fin":"12:00am"}]}, {"tipo":"repeat","dia":"Domingo", "time":[{"flag":"./flag_col.png","ini":"4:00pm","fin":"6:00pm"},{"flag":"./flag_mex.png","ini":"3:00pm","fin":"5:00pm"}]}]},
    { image: './shows/caribbean flow.jpg', title:'Caribbean Flow', alt: "El programa Caribbean Flow semana a semana desde el 2018 nos trae lo nuevo y lo clásico del Dancehall, Reggae, Afrobeat, Soca, Cumbia & Kizomba de la cultura Africaribe. Lo desarrollan la dupla infalible de OneChot y el talentoso productor Dr. Norrys.", schedule:[{"tipo":"live","dia":"Lunes", "time":[{"flag":"./flag_col.png","ini":"3:00pm","fin":"5:00pm"},{"flag":"./flag_mex.png","ini":"2:00pm","fin":"4:00pm"}]}, {"tipo":"repeat","dia":"Sabado", "time":[{"flag":"./flag_col.png","ini":"10:00am","fin":"12:00m"},{"flag":"./flag_mex.png","ini":"9:00am","fin":"10:00am"}]}]},
    { image: './shows/logo_wake_up2.png', title:'Wake Up', alt: '"Wake Up" va más allá de la música; es un llamado a la vida con propósito. Jahia Uncan comparte palabras significativas, fusiona ritmos inspiradores y abraza la meditación para despertar cada día con intención. 🌟🎵 #WakeUp', schedule:[{"tipo":"live","dia":"Lunes", "time":[{"flag":"./flag_col.png","ini":"10:00am","fin":"11:00am"},{"flag":"./flag_mex.png","ini":"9:00am","fin":"10:00am"}]}, {"tipo":"repeat","dia":"Viernes", "time":[{"flag":"./flag_col.png","ini":"10:00am","fin":"11:00am"},{"flag":"./flag_mex.png","ini":"9:00am","fin":"10:00am"}]}]},
    { image: './shows/diaspora.png', title:'Diáspora', alt: 'Programa dedicado a los sonidos de origen afro que han recorrido el mundo generando identidades y múltiples relaciones, acompañados por una charla sobre elementos históricos del proceso diásporico. Dirige DR Tiger', schedule:[{"tipo":"live","dia":"Miércoles", "time":[{"flag":"./flag_col.png","ini":"9:00pm","fin":"11:00pm"},{"flag":"./flag_mex.png","ini":"8:00pm","fin":"10:00pm"}]}, {"tipo":"repeat","dia":"Sábado", "time":[{"flag":"./flag_col.png","ini":"1:00pm","fin":"3:00pm"},{"flag":"./flag_mex.png","ini":"12:00m","fin":"2:00pm"}]}]},
    { image: './shows/logo_pull_it_up.png', title:'Pull It Up', alt: 'Pull it UP! Radio Show, desde Guadalajara México para el mundo, lo mejor del Reggae, Afrobeats, Dancehall, DUB, artistas invitados cada emisión, siguenos en nuestras distintas redes plataformas digitales y nuestra pagina oficial.', schedule:[{"tipo":"live","dia":"Martes", "time":[{"flag":"./flag_col.png","ini":"7:00pm","fin":"9:00pm"},{"flag":"./flag_mex.png","ini":"6:00pm","fin":"8:00pm"}]}, {"tipo":"repeat","dia":"Viernes", "time":[{"flag":"./flag_col.png","ini":"12:00m","fin":"2:00pm"},{"flag":"./flag_mex.png","ini":"11:00","fin":"1:00pm"}]}]},
    { image: './shows/logo_living_072023.jpg', title:'Living Ital', alt: "Living Ital es un programa cuyo objetivo es poder transmitir un poco de las vivencias, nyahbinghi, experiencias y livitys Rastafari, asi como música y cultura general de la diáspora. Conducen Yah Joel y Natty Man", schedule:[{"tipo":"live","dia":"Viernes", "time":[{"flag":"./flag_col.png","ini":"6:00pm","fin":"8:00pm"},{"flag":"./flag_mex.png","ini":"5:00pm","fin":"7:00pm"}]}, {"tipo":"repeat","dia":"Lunes", "time":[{"flag":"./flag_col.png","ini":"1:00pm","fin":"3:00pm"},{"flag":"./flag_mex.png","ini":"12:00m","fin":"2:00pm"}]}]},
    { image: './shows/logo_legado_africano2.jpg', title:'Legado Africano', alt: 'Legado Africano es un programa de radio que empezó en octubre de 2007 en la emisora de la Universidad Pedagógica y Tecnológica de Colombia en Tunja, Boyacá. Mahavisnu, Andrés y Charli como miembros fundadores de este espacio encuentran el medio para compartir su gusto por el reggae roots, ska, dub y diferentes géneros musicales que hacen parte las formas de expresar los sentimientos sociopolíticos y más profundos cánticos de resistencia conociendo así la historia de áfrica y su diáspora en las antillas, el caribe, el pacifico colombiano enfocados principalmente en la influencia del movimiento Rastafari el territorio.', schedule:[{"tipo":"live","dia":"Jueves", "time":[{"flag":"./flag_col.png","ini":"5:00pm","fin":"7:00pm"},{"flag":"./flag_mex.png","ini":"4:00pm","fin":"6:00pm"}]}, {"tipo":"repeat","dia":"Lunes", "time":[{"flag":"./flag_col.png","ini":"9:00pm","fin":"11:00pm"},{"flag":"./flag_mex.png","ini":"8:00pm","fin":"10:00pm"}]}]},
    { image: './shows/logo ital morning.png', title:'Ital Morning', alt: '#Italmorning es un programa mañanero elaborado y dirigido por el equipo de RadioItal.com, en donde a traves de conversaciones, se razonan diferentes temas sobre actualidad, educación, misterio, música, baile, entre otras manifestaciones culturales; Angie, Giovanny, Joel y NattyMan junto a invitados especiales acompañarán y brindarán un matiz a tus mañanas.', schedule:[{"tipo":"live","dia":"Jueves y Viernes", "time":[{"flag":"./flag_col.png","ini":"8:00am","fin":"10:00am"},{"flag":"./flag_mex.png","ini":"7:00am","fin":"9:00am"}]}, {"tipo":"repeat","dia":"Domingo", "time":[{"flag":"./flag_col.png","ini":"10:00am","fin":"12:00m"},{"flag":"./flag_mex.png","ini":"9:00am","fin":"11:00am"}]},{"tipo":"repeat","dia":"Lunes", "time":[{"flag":"./flag_col.png","ini":"8:00am","fin":"10:00am"},{"flag":"./flag_mex.png","ini":"7:00am","fin":"9:00am"}]}]},
  ];

    return (
        <div className="shows-page">
            <div className="shows-content">
                <span className="shows-title">RADIO SHOWS</span>
                <div className="home-chat">
                    <SliderShow shows={slides} autoSlideDelay={3000}/>
                </div>
            </div>
        </div>
    );
};

export default Show;
