import React from 'react';
import { auth } from "./Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import Header from './components/Header/Header.jsx';
import Home from './components/Home/Home.js';
import ContactForm from './components/Contact/Contact.js';
import About from './components/About/About.jsx';
import Partners from './components/Partners/Partners.js';
import StreamingPlayer from './components/Player/StreamingPlayer.js';
import ChatBox from './components/Chat/Chatbox.js';
import Welcome from './components/Chat/Welcome.js';
import Shows from './components/Shows/Show.js';
import Footer from './components/Footer/Footer.jsx';
//
import './App.css'


//require('dotenv').config();


const App = () => {
  const [user] = useAuthState(auth);
  return (
    <Router>
      <div className="app">
        <Header />
        <StreamingPlayer streamUrl="https://cast4.my-control-panel.com/proxy/radioita/stream"/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/contact" element={<ContactForm />} />
          {/* Add more routes and components for other pages */}
        </Routes>
        {!user ? <Welcome /> : <ChatBox />}
        <Shows/>
        <Footer/>
      </div>
    </Router>
  );
};

createRoot(document.getElementById('root')).render(<App />);

export default App;