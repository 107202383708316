import React from 'react';
import Slider from './Slider';
import './HomePage.css';


const HomePage = () => {
  
  const slides = [
    { image: './showcase/ital001.jpg', alt: 'Radioital 001' },
    { image: './showcase/ital002.jpg', alt: 'Radioital 002' },
    { image: './showcase/ital003.jpg', alt: 'Radioital 003' },
    { image: './showcase/ital004.jpg', alt: 'Radioital 004' },
    { image: './showcase/ital005.jpg', alt: 'Radioital 005' },
    { image: './showcase/ital006.jpg', alt: 'Radioital 006' },
    { image: './showcase/ital007.jpg', alt: 'Radioital 007' },
    { image: './showcase/ital008.jpg', alt: 'Radioital 008' },
    { image: './showcase/ital009.jpg', alt: 'Radioital 009' },
    { image: './showcase/ital010.jpg', alt: 'Radioital 010' },
    { image: './showcase/ital011.jpg', alt: 'Radioital 011' },
    { image: './showcase/ital012.jpg', alt: 'Radioital 012' },
    { image: './showcase/ital013.jpg', alt: 'Radioital 013' },
    { image: './showcase/ital014.jpg', alt: 'Radioital 014' },
    { image: './showcase/ital015.jpg', alt: 'Radioital 015' },
    { image: './showcase/ital016.jpg', alt: 'Radioital 016' },
    { image: './showcase/ital017.jpg', alt: 'Radioital 017' },
    { image: './showcase/ital018.jpg', alt: 'Radioital 018' },
    { image: './showcase/ital019.jpg', alt: 'Radioital 019' },
    { image: './showcase/ital020.jpg', alt: 'Radioital 020' },
    { image: './showcase/ital021.jpg', alt: 'Radioital 021' },
    { image: './showcase/ital022.jpg', alt: 'Radioital 022' },
    { image: './showcase/ital023.jpg', alt: 'Radioital 023' },
    { image: './showcase/ital024.jpg', alt: 'Radioital 024' },
    { image: './showcase/ital025.jpg', alt: 'Radioital 025' },
    { image: './showcase/ital026.jpg', alt: 'Radioital 026' },
    { image: './showcase/ital027.jpg', alt: 'Radioital 027' },
    { image: './showcase/ital028.jpg', alt: 'Radioital 028' },
    { image: './showcase/ital029.jpg', alt: 'Radioital 029' },
    { image: './showcase/ital030.jpg', alt: 'Radioital 030' },
    { image: './showcase/ital031.jpg', alt: 'Radioital 031' },
    { image: './showcase/ital032.jpg', alt: 'Radioital 032' },
    { image: './showcase/ital033.jpg', alt: 'Radioital 033' },
    { image: './showcase/ital034.jpg', alt: 'Radioital 034' },
    { image: './showcase/ital035.jpg', alt: 'Radioital 035' },
    { image: './showcase/ital036.jpg', alt: 'Radioital 036' },
    { image: './showcase/ital037.jpg', alt: 'Radioital 037' },
    { image: './showcase/ital038.jpg', alt: 'Radioital 038' },
    { image: './showcase/ital039.jpg', alt: 'Radioital 039' },
    { image: './showcase/ital040.jpg', alt: 'Radioital 040' },
    { image: './showcase/ital041.jpg', alt: 'Radioital 041' },
    { image: './showcase/ital042.jpg', alt: 'Radioital 042' },
    { image: './showcase/ital043.jpg', alt: 'Radioital 043' },
    { image: './showcase/ital044.jpg', alt: 'Radioital 044' },
    { image: './showcase/ital045.jpg', alt: 'Radioital 045' },
    { image: './showcase/ital046.jpg', alt: 'Radioital 046' },
    { image: './showcase/ital047.jpg', alt: 'Radioital 047' },
    { image: './showcase/ital048.jpg', alt: 'Radioital 048' },
    { image: './showcase/ital049.jpg', alt: 'Radioital 049' },
    { image: './showcase/ital050.jpg', alt: 'Radioital 050' },
    { image: './showcase/ital051.jpg', alt: 'Radioital 051' }
  ];

  return (
    <div className="home-page">
        <div className="home-content">
          <h2 className='shows-title'>Showcase</h2>
          <div className="home-chat">
            <Slider slides={slides} />
          </div>
        </div>
      </div>
  );
};

export default HomePage;
